import "./css/style.scss";
/**
 * @param {Event} ev 
 */

 function handleTopMenuClick(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    document.querySelector('.menu-smartphone .content').classList.remove('show');
    
    let isFrontPage = document.querySelector('body').classList.contains('home');
    
    let classes = ev.target.closest('li').classList;
    let slideNumber = null;
    for (let i = 0; i < classes.length; i++) {
        if (classes[i].substring(0, 2) == "tg" && classes[i].length > 2) {
            slideNumber = parseInt(classes[i].substring(3));
            break;
        }
    }
    console.log("Slide number: "+slideNumber);

    if (slideNumber != null) {
        if (isFrontPage) {
            swiper.slideTo(slideNumber);
        } else {
            const params = new URLSearchParams({
                slideTo: slideNumber,
              });
              
              window.location.href = window.homeURL + "?" + params.toString();
        }
    }
}


let topMenuLinks = document.querySelectorAll('section.top-menu ul > li.tg > a');

let topMenuCounter = 0;
topMenuLinks.forEach(el => {
    el.dataset.slidenumber = topMenuCounter;
    topMenuCounter++;
    el.addEventListener('click', handleTopMenuClick)
})

let mobileMenuLinks = document.querySelectorAll('.menu-smartphone ul > li.tg > a');

topMenuCounter = 0;

mobileMenuLinks.forEach(el => {
    el.dataset.slidenumber = topMenuCounter;
    topMenuCounter++;
    el.addEventListener('click', handleTopMenuClick);
})
